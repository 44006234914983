import { WnaAppContext } from "@app/WnaAppContext";
import WnaFileInfo from "@domain/contracts/types/WnaFileInfo";
import { FontAwesome5 } from "@expo/vector-icons";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import { FlashList } from "@shopify/flash-list";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaCardActivityIndicator from "@ui/components/cards/WnaCardActivityIndicator";
import * as DocumentPicker from "expo-document-picker";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { useModal } from "react-native-modalfy";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";
import WnaImage from "./WnaImage";

export type WnaCarouselItem = {
    id: string;
    fileInfo: WnaFileInfo;
};

export interface WnaCarouselProps {
    firebaseItemPath?: string;
    fileInfos: WnaFileInfo[];
    isEditMode: boolean;
    onCollectionChanged: (items: WnaCarouselItem[]) => void;
    onSetIsBusy: (isBusy: boolean) => void;
    onFirst: () => void;
    onLast: () => void;
}

const _itemSize = 256;

const WnaCarousel: FC<WnaCarouselProps> = (props) => {
    const { currentAppTheme, currentAppStyle, appSettings } =
        useContext(WnaAppContext);
    const { openModal } = useModal();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isBusyText, setIsBusyText] = useState("");
    const style = getStyle(_itemSize);
    const [dataWithPlaceholders, setDataWithPlaceholders] = useState<
        WnaCarouselItem[]
    >([]);
    const [currentItemFirebasePath, setCurrentItemFirebasePath] = useState("");
    const currentItemFirebasePathRef = useRef(currentItemFirebasePath);
    const [dataSourceCords, setDataSourceCords] = useState([]);
    const [extraData, setExtraData] = useState(new Date());
    const [currentFileInfos, setCurrentFileInfos] = useState<WnaFileInfo[]>([]);
    const currentFileInfosRef = useRef(currentFileInfos);

    const [isEditMode, setIsEditMode] = useState(false);
    const isEditModeRef = useRef(isEditMode);

    const scrollViewRef = useRef<ScrollView>(null);
    const flRef = useRef<FlashList<WnaCarouselItem>>(null);

    const { t } = useTranslation(["common"]);

    const loadImagesAsync = async (
        currentItemFirebasePath: string,
        forceReload: boolean
    ) => {
        try {
            WnaLogger.start(
                WnaCarousel.name,
                loadImagesAsync.name,
                currentItemFirebasePath
            );
            const wnaCarouselItems = new Array<WnaCarouselItem>();
            if (
                forceReload ||
                (currentItemFirebasePathRef.current !=
                    currentItemFirebasePath &&
                    currentItemFirebasePath != "")
            ) {
                if (props.fileInfos == undefined) {
                    setCurrentFileInfos([]);
                    setDataWithPlaceholders([]);
                    return;
                }

                setCurrentFileInfos(props.fileInfos);
                currentFileInfosRef.current = props.fileInfos;

                const fileInfoKeys =
                    props.fileInfos != undefined
                        ? props.fileInfos.length.toString()
                        : "0";

                const cacheKey = "cinfo_" + currentItemFirebasePath;
                const cacheKeyResult =
                    await WnaAsyncStorageProvider.getItemAsync(
                        cacheKey,
                        false,
                        false
                    );
                const needCache =
                    cacheKeyResult == null ||
                    cacheKeyResult == "" ||
                    cacheKeyResult != fileInfoKeys;

                if (needCache && props.fileInfos.length > 0) {
                    setIsBusy(true);
                    props.onSetIsBusy(true);
                }

                for (let i = 0; i < props.fileInfos.length; i++) {
                    const fileInfo = props.fileInfos[i];
                    if (needCache) {
                        // cache here
                        setIsBusyText(i + 1 + " / " + props.fileInfos.length);
                        const cached256 =
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                fileInfo.urlTh256 ?? "",
                                "png"
                            );
                        const cached512 =
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                fileInfo.urlTh512 ?? "",
                                "png"
                            );
                        const cachedFull =
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                fileInfo.url ?? "",
                                "png"
                            );

                        if (
                            cached256 === "" ||
                            cached512 === "" ||
                            cachedFull === ""
                        ) {
                            throw new Error(
                                `Failed to cache '${fileInfo.fileName}'`
                            );
                        }
                    }

                    // hotfix for app crash
                    if (fileInfo.fileName == undefined)
                        fileInfo.fileName = i + ".png";

                    wnaCarouselItems.push({
                        id: fileInfo.fileName ?? i,
                        fileInfo: fileInfo,
                    });
                }

                if (needCache)
                    await WnaAsyncStorageProvider.setItemAsync(
                        cacheKey,
                        fileInfoKeys,
                        false,
                        false
                    );
            }

            if (wnaCarouselItems.length < appSettings!.maxUploadFiles) {
                wnaCarouselItems.push({
                    id: "BtnAdd",
                    fileInfo: {
                        fileName: "BtnAdd",
                        isLocal: false,
                        isDeleted: false,
                        url: "",
                    } as WnaFileInfo,
                });
            }

            if (
                currentItemFirebasePath != currentItemFirebasePathRef.current ||
                forceReload
            ) {
                WnaLogger.info("setDataWithPlaceholders");
                setDataWithPlaceholders(wnaCarouselItems);
                setCurrentItemFirebasePath(currentItemFirebasePath);
                currentItemFirebasePathRef.current = currentItemFirebasePath;
                if (wnaCarouselItems.length > 0) {
                    if (Platform.OS == "web")
                        scrollViewRef.current?.scrollTo({
                            y: 0,
                            animated: true,
                        });
                    else
                        flRef.current?.scrollToIndex({
                            index: 0,
                            animated: true,
                        });
                }
            }
        } catch (error) {
            WnaLogger.error(WnaCarousel.name, loadImagesAsync.name, error);
        } finally {
            setIsBusy(false);
            setIsBusyText("");
            props.onSetIsBusy(false);
            setIsEditMode(props.isEditMode);
            isEditModeRef.current = props.isEditMode;
            setExtraData(new Date());
            WnaLogger.end(
                WnaCarousel.name,
                loadImagesAsync.name,
                currentItemFirebasePath
            );
        }
    };

    const addImageAsync = async () => {
        try {
            WnaLogger.start(WnaCarousel.name, addImageAsync.name);
            setIsBusy(true);
            setIsBusyText(t(i18nKeys.infoPleaseWait));
            props.onSetIsBusy(true);
            const result = await DocumentPicker.getDocumentAsync({
                copyToCacheDirectory: true,
                type: "image/*",
                multiple: true,
            });

            WnaLogger.info("checking result length");
            if (result.assets == null || result.assets.length < 1) {
                setIsBusy(false);
                setIsBusyText("");
                props.onSetIsBusy(false);
                return;
            }

            setIsBusyText(result.assets.length.toString());
            WnaLogger.info(result.assets.length.toString());
            const wnaCarouselItems = [...dataWithPlaceholders];

            for (let i = 0; i < result.assets.length; i++) {
                const progressVal = (
                    ((i + 1) / result.assets.length) *
                    100
                ).toFixed(0);
                setIsBusyText(progressVal + " %");
                WnaLogger.info(progressVal + " %");
                const fileItem = result.assets[i];

                if (fileItem == null) continue;

                // check if file exists
                const existingItems = dataWithPlaceholders.find(
                    (item) => item.fileInfo.fileName === fileItem!.name
                );
                if (existingItems != undefined) {
                    continue;
                }

                const addedItem = {
                    fileInfo: {
                        url: fileItem.uri,
                        urlTh256: fileItem.uri,
                        urlTh512: fileItem.uri,
                        fileName: fileItem.name,
                        isLocal: true,
                        isDeleted: false,
                    } as WnaFileInfo,
                } as WnaCarouselItem;
                // debugger;
                wnaCarouselItems.splice(
                    wnaCarouselItems.length - 1,
                    0,
                    addedItem
                );
                if (wnaCarouselItems.length > appSettings!.maxUploadFiles) {
                    wnaCarouselItems.pop();
                    break;
                }
            }

            props.onCollectionChanged(
                wnaCarouselItems.filter((x) => x.fileInfo.url != "")
            );
            setIsBusy(false);
            props.onSetIsBusy(false);
            setIsBusyText("");
            setDataWithPlaceholders(wnaCarouselItems);
            setTimeout(() => {
                if (Platform.OS == "web") scrollViewRef.current?.scrollToEnd();
                else flRef.current?.scrollToEnd();
            }, 300);
        } catch (error) {
            WnaLogger.error(WnaCarousel.name, addImageAsync.name, error);
        } finally {
            WnaLogger.end(WnaCarousel.name, addImageAsync.name);
        }
    };

    const setFavourite = (item: WnaFileInfo) => {
        try {
            WnaLogger.start(WnaCarousel.name, setFavourite.name);

            let wnaCarouselItems = [...dataWithPlaceholders];
            wnaCarouselItems.forEach((x) => {
                x.fileInfo.isFavourite = false;
            });
            item.isFavourite = true;
            setDataWithPlaceholders(wnaCarouselItems);
            props.onCollectionChanged(wnaCarouselItems);
        } catch (error) {
            WnaLogger.error(WnaCarousel.name, setFavourite.name, error);
        } finally {
            WnaLogger.end(WnaCarousel.name, setFavourite.name);
        }
    };

    const deleteImageAsync = async (item: WnaFileInfo, undo?: boolean) => {
        try {
            WnaLogger.start(WnaCarousel.name, deleteImageAsync.name);
            item.isDeleted = undo != true;
            let wnaCarouselItems = [...dataWithPlaceholders];
            if (item.isLocal)
                wnaCarouselItems = wnaCarouselItems.filter(
                    (x) => x.fileInfo.fileName != item.fileName
                );

            setDataWithPlaceholders(wnaCarouselItems);
            props.onCollectionChanged(wnaCarouselItems);
        } catch (error) {
            WnaLogger.error(WnaCarousel.name, deleteImageAsync.name, error);
        } finally {
            WnaLogger.end(WnaCarousel.name, deleteImageAsync.name);
        }
    };

    useEffect(() => {
        WnaLogger.start(WnaCarousel.name, useEffect.name);
        if (!isBusy) {
            setIsBusy(true);
            loadImagesAsync(
                props.firebaseItemPath ?? "",
                isEditModeRef.current != props.isEditMode
            );
        }
        WnaLogger.end(WnaCarousel.name, useEffect.name);
    }, [props]);

    const viewImage = (item: WnaFileInfo) => {
        try {
            WnaLogger.start(WnaCarousel.name, viewImage.name);
            const fileInfos = new Array<WnaFileInfo>();
            for (let d of dataWithPlaceholders) {
                if (d.fileInfo.url != "") fileInfos.push(d.fileInfo);
            }
            openModal("WnaImageViewerModal", {
                images: fileInfos,
                currentImage: item,
                onFirst: props.onFirst,
                onLast: props.onLast,
                onIndexChanged: (currentIndex: number) => {
                    if (Platform.OS == "web")
                        scrollViewRef.current?.scrollTo({
                            x: 0,
                            y: dataSourceCords[currentIndex],
                            animated: true,
                        });
                    else
                        flRef.current?.scrollToIndex({
                            index: currentIndex,
                            animated: true,
                        });
                },
            });
        } catch (error) {
            WnaLogger.error(WnaCarousel.name, viewImage.name, error);
        } finally {
            WnaLogger.end(WnaCarousel.name, viewImage.name);
        }
    };

    //@ts-ignore
    const renderItem = (fileInfo: WnaFileInfo) => {
        if ((fileInfo.urlTh256 ?? "") == "" && !isEditMode) return null;
        else if ((fileInfo.urlTh256 ?? "") == "" && isEditMode)
            // add new image
            return (
                <View key={fileInfo.fileName} style={style.itemContent}>
                    <WnaPressable
                        toolTip={t(i18nKeys.actionAddPicture)}
                        onPress={addImageAsync}
                        style={{
                            minHeight: _itemSize,
                            width: _itemSize,
                            borderRadius: 16,
                        }}
                        t={t}
                        checkInternetConnection={false}>
                        <View
                            style={[
                                {
                                    minHeight: _itemSize,
                                    width: _itemSize,
                                    backgroundColor: convertHexToRgba(
                                        currentAppTheme.colors.coolgray4,
                                        0.5
                                    ),
                                },
                                currentAppStyle.containerCenterCenter,
                            ]}>
                            <FontAwesome5
                                size={42}
                                name={"camera"}
                                color={currentAppTheme.colors.coolgray6}
                            />
                        </View>
                    </WnaPressable>
                </View>
            );
        // view image
        else
            return (
                <View
                    key={fileInfo.fileName}
                    onLayout={(event) => {
                        try {
                            const layout = event.nativeEvent.layout;
                            const indexOfItem = dataWithPlaceholders.findIndex(
                                (x) => x.fileInfo.fileName == fileInfo.fileName
                            );
                            //@ts-ignore
                            dataSourceCords[indexOfItem] = layout.y;
                            setDataSourceCords(dataSourceCords);
                        } catch (error) {}
                    }}
                    style={style.itemContent}>
                    <WnaPressable
                        disableHover
                        style={style.itemImage}
                        toolTipPosition="top"
                        onPress={() => {
                            if (fileInfo.isDeleted) {
                                // undo delete
                                deleteImageAsync(fileInfo, true);
                            } else {
                                viewImage(fileInfo);
                            }
                        }}
                        t={t}
                        checkInternetConnection={false}>
                        <View>
                            <WnaImage
                                currentAppTheme={currentAppTheme}
                                imageUrl={fileInfo.urlTh512}
                                style={[
                                    style.itemImage,
                                    {
                                        opacity: fileInfo.isDeleted ? 0.5 : 1,
                                    },
                                ]}
                            />
                            {fileInfo.isDeleted ? (
                                <View
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: convertHexToRgba(
                                            currentAppTheme.colors.staticBlack,
                                            0.7
                                        ),
                                    }}>
                                    <FontAwesome5
                                        size={48}
                                        name={"trash-alt"}
                                        color={
                                            currentAppTheme.colors.staticWhite
                                        }
                                    />
                                </View>
                            ) : null}
                            <View
                                style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    flex: 1,
                                    flexDirection: "row",
                                    gap: 16,
                                    alignItems: "center",
                                }}>
                                {!fileInfo.isLocal ||
                                fileInfo.isDeleted ? null : (
                                    <FontAwesome5
                                        size={24}
                                        name={"upload"}
                                        color={
                                            currentAppTheme.colors.staticWhite
                                        }
                                    />
                                )}
                                {!isEditMode || fileInfo.isDeleted ? null : (
                                    <>
                                        <WnaButtonIcon
                                            currentAppStyle={currentAppStyle}
                                            currentAppTheme={currentAppTheme}
                                            iconName="trash-alt"
                                            onPress={() =>
                                                deleteImageAsync(fileInfo)
                                            }
                                            t={t}
                                            checkInternetConnection={false}
                                        />
                                        <WnaButtonIcon
                                            currentAppStyle={currentAppStyle}
                                            currentAppTheme={currentAppTheme}
                                            iconName={
                                                fileInfo.isFavourite
                                                    ? "star"
                                                    : "circle"
                                            }
                                            onPress={() =>
                                                setFavourite(fileInfo)
                                            }
                                            color={
                                                fileInfo.isFavourite
                                                    ? currentAppTheme.colors
                                                          .yellow7
                                                    : currentAppTheme.colors
                                                          .staticWhite
                                            }
                                            t={t}
                                            checkInternetConnection={false}
                                        />
                                    </>
                                )}
                            </View>
                        </View>
                    </WnaPressable>
                </View>
            );
    };

    return (
        <>
            {Platform.OS == "web" ? (
                <ScrollView
                    ref={scrollViewRef}
                    contentContainerStyle={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                    pointerEvents={isBusy ? "none" : "auto"}>
                    {dataWithPlaceholders.map(({ fileInfo }) =>
                        renderItem(fileInfo)
                    )}
                </ScrollView>
            ) : (
                <FlashList
                    data={dataWithPlaceholders}
                    keyExtractor={(item, index) => {
                        let ret = "";
                        try {
                            ret = item.fileInfo.fileName;
                        } catch (error) {
                            WnaLogger.error(
                                WnaCarousel.name,
                                `FlashListKeyExtractor - ${index}`,
                                error
                            );
                        }
                        return ret;
                    }}
                    renderItem={({ item }) => renderItem(item.fileInfo)}
                    pointerEvents={isBusy ? "none" : "auto"}
                    extraData={extraData}
                    ref={flRef}
                    estimatedItemSize={_itemSize}
                />
            )}
            {isBusy ? (
                <View
                    style={[
                        {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            flex: 1,
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: convertHexToRgba("#000000", 0.7),
                        },
                    ]}
                    pointerEvents="none">
                    <WnaCardActivityIndicator
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        isBusyText={isBusyText}
                    />
                </View>
            ) : null}
        </>
    );
};

export default WnaCarousel;

const getStyle = (itemSize: Int32) =>
    StyleSheet.create({
        itemContent: {
            alignItems: "center",
            margin: 16,
            borderRadius: 16,
        },
        itemImage: {
            height: itemSize,
            width: itemSize,
            borderRadius: 16,
        },
    });
