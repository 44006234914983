import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaNavigationItem from "@ui/components/navigation/WnaNavigationItem";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaSettingsScreen: React.FC<WnaStackScreenProps> = (props) => {
    const { currentAppTheme, currentAppStyle } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [
        {
            screen: WnaApplicationConstants.ScreenNameSettingsDiary,
            text: t(i18nKeys.wordDiary),
            icon: "book-open",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameSettingsMap,
            text: t(i18nKeys.wordMap),
            icon: "map",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameSettingsAdvanced,
            text: t(i18nKeys.wordAdvanced),
            icon: "cogs",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.actionClearCache),
            icon: "trash-alt",
            rightIcon: "",
            type: "function",
            data: undefined,
        },
    ];

    const handleClick = async (text: string) => {
        try {
            WnaLogger.start(WnaSettingsScreen.name, handleClick.name, text);
            const currentItem = items.find((x) => x.text === text)!;

            if (currentItem.text == t(i18nKeys.actionClearCache)) {
                await clearCacheAsync();
                return;
            }

            // navigate
            if (currentItem.screen == "") return;
            props.navigation.push(currentItem.screen, {
                currentItem,
            });
        } catch (error) {
            WnaLogger.error(WnaSettingsScreen.name, handleClick.name, error);
        } finally {
            WnaLogger.end(WnaSettingsScreen.name, handleClick.name, text);
        }
    };

    const renderItem = (currentItem: WnaMenuItem) => (
        <WnaNavigationItem
            currentAppStyle={currentAppStyle}
            currentAppTheme={currentAppTheme}
            text={currentItem.text}
            iconName={currentItem.icon}
            onPress={handleClick}
            iconRightName={currentItem.rightIcon}
            t={t}
        />
    );

    const clearCacheAsync = async () => {
        try {
            WnaLogger.start(WnaSettingsScreen.name, clearCacheAsync.name);

            await WnaAsyncFileCacheProvider.clearCacheAsync();
            await WnaAsyncStorageProvider.clearAsync("diaryentries");
            await WnaAsyncStorageProvider.clearAsync("chapters");
            await WnaAsyncStorageProvider.clearAsync("routes");

            WnaToastProvider.showSuccess(t(i18nKeys.success));
        } catch (error) {
            WnaLogger.error(
                WnaSettingsScreen.name,
                clearCacheAsync.name,
                error
            );
            WnaToastProvider.showError(t(i18nKeys.errorUnknown));
        } finally {
            WnaLogger.end(WnaSettingsScreen.name, clearCacheAsync.name);
        }
    };

    return (
        <WnaBaseScreenWithInfo>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaSettingsScreen;
