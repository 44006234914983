import { WnaAppContext } from "@app/WnaAppContext";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import WnaImageBackground from "@ui/components/images/WnaImageBackground";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

export type WnaBaseScreenWithInfoProps = {
    children: React.ReactNode;
    isBusy?: boolean;
    isBusyText?: string | null;
    backgroundImageUrl?: string;
};
const WnaBaseScreenWithInfo: React.FC<WnaBaseScreenWithInfoProps> = (props) => {
    const pkg = require("wna-app-json");
    const { t } = useTranslation(["common"]);
    const {
        currentAppTheme,
        currentAppStyle,
        isInternetReachable,
        currentUserSettings,
        darkAppBackgroundImage,
        lightAppBackgroundImage,
    } = useContext(WnaAppContext);
    const [currentInfoColor, setCurrentInfoColor] = useState(
        currentAppTheme.colors.staticAccent6
    );
    useEffect(() => {
        if (
            isInternetReachable &&
            (currentUserSettings?.appIsOfflineCacheEnabled ?? false)
        ) {
            // force using cache
            setCurrentInfoColor(currentAppTheme.colors.staticCoolgray6);
        } else {
            if (isInternetReachable)
                setCurrentInfoColor(currentAppTheme.colors.staticAccent6);
            else setCurrentInfoColor(currentAppTheme.colors.red4);
        }
    }, [isInternetReachable, currentUserSettings]);

    return (
        <WnaImageBackground
            image={
                props.backgroundImageUrl != null &&
                props.backgroundImageUrl != ""
                    ? props.backgroundImageUrl
                    : currentAppTheme.dark
                      ? darkAppBackgroundImage
                      : lightAppBackgroundImage
            }
            blurRadius={
                props.backgroundImageUrl != null &&
                props.backgroundImageUrl != ""
                    ? 16
                    : undefined
            }>
            <View
                style={{
                    flex: 1,
                    backgroundColor:
                        props.backgroundImageUrl != null &&
                        props.backgroundImageUrl != ""
                            ? convertHexToRgba(
                                  currentAppTheme.colors.white,
                                  0.7
                              )
                            : "transparent",
                }}>
                {props.isBusy ? (
                    <View style={[currentAppStyle.containerCenterCenter]}>
                        <WnaActivityIndicator
                            currentAppTheme={currentAppTheme}
                        />
                        {props.isBusyText != null && props.isBusyText != "" ? (
                            <Text
                                style={[
                                    currentAppStyle.textTitleLarge,
                                    { margin: 16, textAlign: "center" },
                                ]}>
                                {props.isBusyText}
                            </Text>
                        ) : null}
                    </View>
                ) : (
                    props.children
                )}
            </View>
            <LinearGradient
                start={[0, 1]}
                end={[1, 1]}
                colors={[currentAppTheme.colors.white, currentInfoColor]}
                pointerEvents="none"
                style={[
                    {
                        paddingVertical: 2,
                        paddingHorizontal: 16,
                        alignItems: "flex-end",
                    },
                ]}>
                <Text
                    style={{
                        fontSize: 8,
                        color: currentAppTheme.colors.staticWhite,
                    }}>
                    {isInternetReachable
                        ? ""
                        : `${t(i18nKeys.errorNoInternet).toUpperCase()} | `}
                    APP VERSION {pkg.expo.version}
                </Text>
            </LinearGradient>
        </WnaImageBackground>
    );
};
export default WnaBaseScreenWithInfo;
